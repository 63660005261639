import React from 'react';
import css from 'styled-jsx/css';
import UIButton from '../button';
import { withRouter } from "react-router";

const styles = css.global`
  .page-403 {
    padding: 40px;
    background: var(--third-color) !important;
  }

  .page-403 h1 {
    font-size: 3rem;
    color: var(--primary-color);
  }

  .page-403 h2 {
    font-size: 1.5rem;
    color: var(--second-color);
  }

  .page-403 .btn {
    margin-top: 10px;
  }
`;

const Page403 = ({ history }) => (
  <div className="page-403 w-1/3 h-1/2 m-auto text-center py-32 rounded mt-16">
    <h1>403</h1>
    <h2>Access denied</h2>
    <UIButton className="gray btn" onClick={() => history.replace('/')}>Home page</UIButton>
    <style jsx>{styles}</style>
  </div>
);

export default withRouter(Page403);
