import * as React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import DefaultMain from "@app/components/main";
import PrivateRoute from "@app/components/private-route";
import {LoadingPage} from "@app/components/core/loading";

const routes = [
  {
    name: "Login",
    component: React.lazy(() => Promise.all([
      import('@app/modules/auth/components/login'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/login',
    id: 'login',
    exact: true,
    private: false,
    can: 'view'
  },
  // {
  //   name: "request pass",
  //   component: React.lazy(() => Promise.all([
  //     import('@app/modules/auth/components/request-pass'),
  //     new Promise((resolve) => setTimeout(resolve, 1000)),
  //   ])
  //     .then(([moduleExports]) => moduleExports)),
  //   path: '/request-password',
  //   id: 'request-password',
  //   exact: true,
  //   private: false,
  //   can: 'view'
  // },
  // {
  //   name: "reset-password-success",
  //   component: React.lazy(() => Promise.all([
  //     import('@app/modules/auth/components/success'),
  //     new Promise((resolve) => setTimeout(resolve, 1000)),
  //   ])
  //     .then(([moduleExports]) => moduleExports)),
  //   path: '/success',
  //   id: 'success',
  //   exact: true,
  //   private: false,
  //   can: 'view'
  // },
  {
    name: "Apps",
    component: React.lazy(() => Promise.all([
      import('@app/modules/apps'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/',
    id: 'apps',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: 'Snapshots',
    component: React.lazy(() =>
      Promise.all([
        import('@app/modules/snapshots'),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: '/snapshots/:appID',
    id: 'snapshots',
    exact: true,
    private: true,
    can: 'view',
  },
  {
    name: "PWA",
    component: React.lazy(() => Promise.all([
      import('@app/modules/pwa'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/pwa',
    id: 'pwa',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "Sender",
    component: React.lazy(() => Promise.all([
      import('@app/modules/sender'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/sender',
    id: 'sender',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "Dashboard",
    component: React.lazy(() => Promise.all([
      import('@app/modules/dashboard'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/dashboard',
    id: 'dashboard',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "Server",
    component: React.lazy(() => Promise.all([
      import('@app/modules/server'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/server',
    id: 'server',
    exact: true,
    private: true,
    can: 'view'
  },
  // {
  //   name: "Push",
  //   component: React.lazy(() => Promise.all([
  //     import('@app/modules/push'),
  //     new Promise((resolve) => setTimeout(resolve, 1000)),
  //   ])
  //     .then(([moduleExports]) => moduleExports)),
  //   path: '/push',
  //   id: 'push',
  //   exact: true,
  //   private: true,
  //   can: 'view'
  // },
  {
    name: "Landing",
    component: React.lazy(() => Promise.all([
      import('@app/modules/landing'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/landing',
    id: 'landing',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "Subscriptions",
    component: React.lazy(() => Promise.all([
      import('@app/modules/subscription'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/subs',
    id: 'subs',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "Ads",
    component: React.lazy(() => Promise.all([
      import('@app/modules/ad'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/ads',
    id: 'ads',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "User Detail",
    component: React.lazy(() => Promise.all([
      import('@app/modules/user/component/detail'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/users/:id/:type',
    id: 'users-detail',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "User",
    path: '/users',
    component: React.lazy(() => Promise.all([
      import('@app/modules/user'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    id: 'users',
    exact: true,
    private: true,
    can: 'view'
  },
  {
    name: "My Profile",
    component: React.lazy(() => Promise.all([
      import('@app/modules/user/component/profile'),
      new Promise((resolve) => setTimeout(resolve, 1000)),
    ])
      .then(([moduleExports]) => moduleExports)),
    path: '/profile',
    id: 'profile',
    exact: true,
    private: true,
    can: 'edit'
  }
]

const MakeRoute = ({isDarkMode, toggleTheme}) => (
  <DefaultMain isDarkMode={isDarkMode} toggleTheme={toggleTheme}>
    <React.Suspense fallback={<LoadingPage/>}>
      <Switch>
        {
          routes.map((route) => (
            !route?.private
              ? <Route
                exact={route.exact || false}
                path={route.path}
                key={route.id}
                render={(props) => {
                  return <route.component {...props}/>
                }}
              />
              : <PrivateRoute
                can={route.can}
                exact={route.exact || false}
                path={route.path}
                key={route.id}
                component={route.component}
              />
          ))
        }
        <Route render={() => (
          <div>Not Found</div>
        )}/>
      </Switch>
    </React.Suspense>
  </DefaultMain>
);

export default MakeRoute;
