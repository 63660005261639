import React from "react";
import { useContext, useState, createContext } from "react";

export const AppContext = createContext(null);

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
  const [convertedErrors, setConvertedErrors] = useState({});

  const setConvertedErr = (errors) => {
    setConvertedErrors(errors);
  };

  return (
    <AppContext.Provider
      value={{
        convertedErrors,
        setConvertedErr,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
